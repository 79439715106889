var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      ref: "cityDynamicTable",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        "tooltip-effect": "dark",
      },
    },
    [
      _c("el-table-column", {
        attrs: { prop: "business.name", align: "center", label: "集配中心" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$empty.empty(scope.row?.business?.name)) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "store_logistics.name",
          align: "center",
          label: "城市仓",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row?.store_logistics?.name) + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "store_logistics.id",
          align: "center",
          label: "城市仓ID",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$empty.empty(scope.row?.store_logistics?.id)) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "username", align: "center", label: "持卡人" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$empty.empty(
                        scope.row?.store_logistics_bank?.username
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "idcard_name", align: "center", label: "身份证姓名" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$empty.empty(
                        scope.row?.store_logistics_bank?.idcard_name
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "idcard_code", align: "center", label: "身份证号码" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$empty.empty(
                        scope.row?.store_logistics_bank?.idcard_code
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "revenue_expend_type", align: "center", label: "收支" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.incomeType.find(
                        (item) => item.value == scope.row?.revenue_expend_type
                      ).label || "-"
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "amount", align: "center", label: "金额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.tool.format_money(scope.row?.amount)) + " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "create_time", align: "center", label: "时间" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row?.create_time || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "type", align: "center", label: "类型" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(_vm.getTypeName(scope.row?.type)))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "desc", align: "center", label: "描述" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row?.desc) + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "wallet_amount", align: "center", label: "账户余额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.tool.format_money(scope.row?.wallet_amount)) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
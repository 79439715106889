<template>
  <div class="orderlist bj freight-container">
    <div class="title">
      <div class="d-flex a-center">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="formData.business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="开始时间起"
            end-placeholder="开始时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs multiple-select">
          <el-select
            @change="cityChange"
            filterable
            v-model="selectCityId"
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.logistics_id"
            placeholder="城市仓ID"
            clearable
            @input="handleInput"
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.idcard_name"
            placeholder="身份证姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.bank_username"
            placeholder="持卡人"
            clearable
          ></el-input>
        </div>
        <div class="inputs d-flex a-center">
          <el-input
            v-model="formData.min_wallet_amount"
            placeholder="余额起"
            clearable
            @input="
              handleInputMoney(formData.min_wallet_amount, 'min_wallet_amount')
            "
          ></el-input>
          <div class="">-</div>
          <el-input
            v-model="formData.max_wallet_amount"
            placeholder="余额止"
            clearable
            @input="
              handleInputMoney(formData.max_wallet_amount, 'max_wallet_amount')
            "
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.idcard_code"
            placeholder="身份证号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select v-model="formData.type" clearable placeholder="类型">
            <el-option
              v-for="item of typeList"
              :key="item.val"
              :label="item.name"
              :value="item.val"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.revenue_expend_type"
            clearable
            placeholder="收支"
          >
            <el-option
              v-for="item of incomeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <table-list
        :tableData="tableData"
        v-loading="loading"
        :typeList="typeList"
        :incomeType="incomeType"
      ></table-list>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { BASE } from "@/api";
import url from "url";
import TableList from "./modules/table-list";
import {
  postLogisticsWalletFlow,
  postLogisticsWalletFlowType,
} from "@/api/generalControl/deduction-manage/index.js";
import { postLogisticsWalletFlowExport } from "@/api/export/center.js";
import { DYNAMIC_INCOME_TYPE_ENUM } from "../utils/enum/index.js";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "city-dynamic-data",
  components: { TableList },
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      pay_at: "",
      selectCityId: "",
      formData: {
        business_id: "",
        start_time: "",
        end_time: "",
        logistics_id: "",
        idcard_name: "",
        idcard_code: "",
        bank_username: "",
        type: "",
        revenue_expend_type: "",
        min_wallet_amount: "",
        max_wallet_amount: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [],
      total: 0,

      logisticsList: [],
      disabledExport: false, // 导出禁用（导出列表）
      cityStoreList: [], // 城市仓列表
      typeList: [],
      incomeType: Object.values(DYNAMIC_INCOME_TYPE_ENUM), // 收支类型
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.hqlist();
    this.getAjaxLogisticsList();
    this.postAjaxLogisticsWalletFlowType();
    this.ajaxGetCityStoreList();
  },
  methods: {
    /**
     * 城市仓id 文本框只能输入数字
     */
    handleInput(value) {
      // 使用正则表达式限制只能输入数字
      this.formData.supplier_id = value.replace(/[^\d]/g, "");
    },
    /**
     * 余额 文本框 正则表达式来限制输入，只允许负数、正数、0以及小数
     */
    handleInputMoney(value, name) {
      console.log(value, name, "==");
      this.formData[name] = value.replace(/[^\-?\d.]/g, "");
      console.log(value, this.formData[name], "==");
    },
    /**
     * 获取类型列表
     */
    async postAjaxLogisticsWalletFlowType() {
      try {
        const { data } = await postLogisticsWalletFlowType();
        this.typeList = data;
      } catch (err) {
        console.log("ajax postLogisticsWalletFlowType err", err);
      }
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      const params = {
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    },

    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postLogisticsWalletFlowExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postPurchaseSettleExport err", err);
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 城市仓下拉选中赋值给城市仓id
     */
    cityChange(val) {
      this.formData.logistics_id = val;
    },
    /**
     * 查询
     */
    search() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 刷新重置
     */
    Refresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.selectCityId = "";
      this.pay_at = "";
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    handleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
    /**
     * 时间发生改变
     */
    payChange(val) {
      console.log(val);
      if (val) {
        this.formData.start_time = val[0];
        this.formData.end_time = val[1];
      } else {
        this.formData.start_time = "";
        this.formData.end_time = "";
      }
    },
    /**
     * 列表数据
     */
    async hqlist() {
      this.loading = true;
      try {
        const res = await postLogisticsWalletFlow(this.formData);
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postSupplierWalletFlow");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.freight-container.orderlist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .bjsearch {
    background: #333333;
    border-color: #333333;
    margin-right: 5px;
  }

  .bjsearch:active {
    background: #333333;
    border-color: #333333;
  }

  .checked-box {
    padding: 13px 15px;
  }

  .title {
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    .title-style {
      color: #999999;
      span {
        color: #13ae67;
        font-size: 20px;
      }
    }
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        margin-bottom: 5px;
      }

      .multiple-select {
        width: 250px;
      }
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
